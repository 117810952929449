<template>
  <div>
    <list
      ref="refElementsList"
      sort-by="ElementoId"
      refetch-records-name="elementos"
      key-field="ElementoId"
      report-name="Elementos.xlsx"
      :actions="actions"
      :filters.sync="filters"
      :table-columns="tableColumns"
      :table-actions="tableActions"
      :refetch-records="fetchElement"
      :delete-method="removeElement"
    />
  </div>
</template>

<script>
import i18n from '@/libs/i18n'
import { ref } from '@vue/composition-api'
import List from '@/components/List.vue'
import dashboardService from '@/services/dashboard.service'

export default {
  components: {
    List,

  },
  setup() {
    const refElementsList = ref(null)
    const {
      fetchElement,
      removeElement,
    } = dashboardService()

    const filters = ref([
      {
        type: 'date',
        name: 'fechaDesde',
        label: 'Fecha previo desde',
        value: '',
      },
    ])

    const tableColumns = ref([
      { label: i18n.t('settings.dashboards.elements.list.elementType'), key: 'tableroElementoTipo.nombre', sortable: false },
      { label: i18n.t('settings.dashboards.elements.list.name'), key: 'nombre', sortable: false },
      { label: i18n.t('settings.dashboards.elements.list.description'), key: 'descripcion', sortable: false },
      { label: i18n.t('Lists.Actions'), key: 'actions' },
    ])

    const tableActions = ref([
      {
        name: 'edit',
        label: i18n.t('Lists.Edit'),
        aclAction: 'write',
        aclResource: 'Tableros',
        routeName: 'settings-dashboards-elements-edit',
        params: ['elementoId'],
        icon: 'EditIcon',
      },
      {
        name: 'delete',
        label: i18n.t('Lists.Delete'),
        aclAction: 'delete',
        aclResource: 'Tableros',
        params: ['elementoId'],
        icon: 'TrashIcon',
      },
    ])

    const actions = ref([
      {
        label: i18n.t('Lists.Add'),
        aclAction: 'create',
        aclResource: 'Tableros',
        routeName: 'settings-dashboards-elements-new',
        params: [],
        // click: () => { isAddNewUserSidebarActive.value = true },
        icon: 'PlusCircleIcon',
      },
    ])

    return {
      refElementsList,

      filters,
      tableColumns,
      tableActions,
      actions,

      fetchElement,
      removeElement,
    }
  },
}
</script>

<style>

</style>
